<template>
  <transition name="fade">
    <div
      v-if="showModal"
      class="fixed left-4 right-4 bottom-4 max-w-md bg-brand-deep-blue-5 rounded-sm py-4 z-50">
      <div class="sm:flex-1 px-4 md:px-6 text-sm">
        {{ translations[locale].text.cookies_text }}
      </div>

      <div class="flex px-4 md:px-6 mt-4 gap-3">
        <v-button variation="primary" class="w-1/2" style="min-width: 0" @click="accept">
          {{ translations[locale].text.accept }}
        </v-button>

        <v-button variation="secondary" class="w-1/2" style="min-width: 0" @click="decline">
          {{ translations[locale].text.decline }}
        </v-button>
      </div>
    </div>
  </transition>
</template>

<script>
import Button from '@/components/Button/Button.vue'
// import Cookie from '@/cookie'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'
import { mapActions } from 'vuex'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

const DONT_ASK_PERIOD = 30 * 24 * 60 * 60 * 1000 // 30 days in milliseconds

export default {
  name: 'CookiePopup',
  data () {
    return {
      locale: this.$route.params.lang,
      translations
    }
  },
  components: {
    'v-button': Button
  },
  computed: {
    showModal () {
      return this.$store.state.showCookieConsent
    }
  },
  mounted () {
    const cookies = document.cookie.split(';')
    const cookieConsent = cookies.find(cookie => cookie.trim().startsWith('cookies-consent='))

    if (cookieConsent) {
      try {
        const cookieValue = decodeURIComponent(cookieConsent.split('=')[1])
        const { consent, dontAskUntil } = JSON.parse(cookieValue)

        if (consent === 'yes') {
          this.setCookieConsent(true)
          this.toggleCookieConsent(false)
          this.loadAdobeDTM()
        } else if (consent === 'no') {
          this.setCookieConsent(false)
          if (new Date(dontAskUntil) > new Date()) {
            this.toggleCookieConsent(false)
          } else {
            this.toggleCookieConsent(true)
          }
        } else {
          this.toggleCookieConsent(true)
          this.setCookieConsent(false)
        }
      } catch (e) {
        console.error('Error parsing cookie:', e)
        this.toggleCookieConsent(true)
        this.setCookieConsent(false)
      }
    } else {
      this.toggleCookieConsent(true)
      this.setCookieConsent(false)
    }
  },
  methods: {
    ...mapActions(['setCookieConsent', 'toggleCookieConsent']),
    accept () {
      const expirationDate = new Date(Date.now() + DONT_ASK_PERIOD)
      const cookieValue = JSON.stringify({
        consent: 'yes',
        dontAskUntil: expirationDate.toISOString()
      })

      document.cookie = `cookies-consent=${encodeURIComponent(cookieValue)}; expires=${expirationDate.toUTCString()}; path=/`

      this.setCookieConsent(true)
      this.toggleCookieConsent(false)
      this.$nextTick(() => {
        this.loadAdobeDTM()
      })
    },
    decline () {
      const expirationDate = new Date(Date.now() + DONT_ASK_PERIOD)
      const cookieValue = JSON.stringify({
        consent: 'no',
        dontAskUntil: expirationDate.toISOString()
      })

      document.cookie = `cookies-consent=${encodeURIComponent(cookieValue)}; expires=${expirationDate.toUTCString()}; path=/`

      this.toggleCookieConsent(false)
    },
    loadAdobeDTM () {
      const adobeScript = document.getElementById('adobe-dtm')
      if (adobeScript && !adobeScript.src) {
        adobeScript.type = 'text/javascript'
        adobeScript.src = adobeScript.getAttribute('data-src')
        adobeScript.onload = () => {
          this.executeSatellitePageBottom()
        }
      } else {
        // If the script is already loaded, try to execute _satellite.pageBottom()
        this.executeSatellitePageBottom()
      }
    },
    executeSatellitePageBottom () {
      if (window._satellite && typeof window._satellite.pageBottom === 'function') {
        window._satellite.pageBottom()
      } else {
        // If _satellite is not available immediately, wait a bit and try again
        setTimeout(() => {
          if (window._satellite && typeof window._satellite.pageBottom === 'function') {
            window._satellite.pageBottom()
          }
        }, 1000) // Wait for 1 second before retrying
      }
    }
  }
}
</script>

<template>
  <div
    class="c-media-footer"
    :class="[ videoName || videoUrl ? 'is-video' : '' ]"
    :style="{backgroundImage: 'url(' + apiUrl + '/uploads/'+ imageName +')'}"
    @click="playVideo"
  >
    <div
      v-if="(videoName || videoUrl)"
      class="c-media-footer__play"
      @mouseleave="showCookieConsent = false"
    >
      <div
        v-if="showCookieConsent"
        class="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-brand-vibrant-petrol text-sm text-center w-56 leading-tight px-2 py-2 rounded-sm">
        {{ translations[locale].text.please_accept }}
      </div>
      <v-icon type="play" size="96"/>
    </div>

    <template v-if="(videoName || videoUrl) && videoType === 'youtube'">
      <iframe
        v-show="showVideo"
        ref="youtubeVideo"
        class="c-media-footer__video"
        id="video"
        allowfullscreen
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
    </template>

    <template v-if="(videoName || videoUrl) && videoType === 'html'">
      <video
        v-show="showVideo"
        controls
        ref="htmlVideo"
        muted
        class="c-media-footer__video"
      >
        <source
          :src="apiUrl + '/uploads/' + videoName"
          type="video/mp4"
        >
      </video>
    </template>
  </div>
</template>

<script>
import Icon from '@/components/Icon/Icon.vue'
import { mapGetters, mapActions } from 'vuex'
// import Cookie from '@/cookie'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

export default {
  name: 'MediaFooter',
  components: {
    'v-icon': Icon
  },
  props: {
    imageName: String,
    videoUrl: String,
    videoType: String,
    videoName: String
  },
  data () {
    return {
      locale: this.$route.params.lang,
      translations,
      showVideo: false,
      showCookieConsent: false
    }
  },
  computed: {
    ...mapGetters([
      'apiUrl'
    ])
  },
  methods: {
    ...mapActions(['setCookieConsent', 'toggleCookieConsent']),
    playVideo () {
      if (this.videoName || this.videoUrl) {
        if (this.videoType === 'youtube') {
          const cookies = document.cookie.split(';')
          const cookieConsent = cookies.find(cookie => cookie.trim().startsWith('cookies-consent='))

          if (cookieConsent) {
            try {
              const cookieValue = decodeURIComponent(cookieConsent.split('=')[1])
              const { consent } = JSON.parse(cookieValue)

              if (consent === 'yes') {
                this.$refs.youtubeVideo.src = `${this.videoUrl}?autoplay=1&mute=1`
                this.showVideo = true
                this.showCookieConsent = false
              } else {
                this.showCookieConsent = true
                this.toggleCookieConsent(true)
              }
            } catch (e) {
              console.error('Error parsing cookie consent:', e)
              this.showCookieConsent = true
              this.toggleCookieConsent(true)
            }
          } else {
            this.showCookieConsent = true
            this.toggleCookieConsent(true)
          }
        } else if (this.videoType === 'html') {
          this.$refs.htmlVideo.muted = true
          this.$refs.htmlVideo.play()
          this.showVideo = true
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.c-media-footer {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding-bottom: 39.21568627450981%;
  position: relative;

  &.is-video {
    cursor: pointer;
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: #000;

    &:focus {
      outline: none;
    }
  }
}
</style>

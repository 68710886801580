
import App from '@/App/App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets/scss/global.scss'
import { createApp, h } from 'vue'
import AOS from 'aos'
import 'aos/dist/aos.css'

(() => {
  const originalDocumentCookie = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie') || Object.getOwnPropertyDescriptor(HTMLDocument.prototype, 'cookie')

  Object.defineProperty(document, 'cookie', {
    get: () => originalDocumentCookie.get.call(document),
    set: (value) => {
      const cookies = document.cookie.split(';')
      const cookieConsent = cookies.find(cookie => cookie.trim().startsWith('cookies-consent='))

      if (cookieConsent) {
        try {
          const cookieValue = decodeURIComponent(cookieConsent.split('=')[1])
          const { consent } = JSON.parse(cookieValue)
          if (consent === 'yes' || value.indexOf('cookies-consent=') === 0) {
            originalDocumentCookie.set.call(document, value)
          }
        } catch (e) {
          console.error('Error parsing cookie consent:', e)
        }
      } else if (value.indexOf('cookies-consent=') === 0) {
        originalDocumentCookie.set.call(document, value)
      }
    },
    configurable: true
  })
})()

const app = createApp({
  created () {
    AOS.init()
  },
  render: () => h(App)
})

app.use(router)
app.use(store)
app.mount('#app')
